@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Lato', sans-serif; */
}

.bottom-menu {
  position: absolute;
  bottom: 10px;
  left: 150px;
  margin-bottom: 30px;
}

.bottom-inline0{
  color: #f5f5f5;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  margin-right: 15px;
  /* transition: text-decoration 0.3s; */
  padding-bottom: 5px;
}
 .bottom-inline0:hover{
  /* text-decoration: underline; */
  transition: all 0.2s;
  border-bottom: 3px solid #ffffff;
}

.bottom-inline1{
  color: #f5f5f5;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 5px;
}
.bottom-inline1:hover{
  /* text-decoration: underline; */
  transition: all 0.2s;
  border-bottom: 3px solid #ffffff;
}
 