
/* .menu-bars {
  top: 0;
  margin-left: 2rem;
  margin-top: 520px;
  font-size: 2rem;
  background: none;
  color: #f5f5f5;
  padding-top: 530px;
} */

.nav-action-btn {
  color: #ffffff;
  cursor: pointer;
  z-index: 2;
}
.ham {
  position: fixed;
  top: 280px;
  left: 30px;
  align-items: center;
}

.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.nav-menu {
  background-color: #000000;
  font-family: "Kufam", sans-serif;
  /* width: 100vw; */
  width: 490px;
  height: 100vh;
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  translate: -110%;
  transition: 1000ms;
  /* justify-content: space-around; */
  flex-direction: column;
}
@media (max-width: 767px) {
  .nav-menu {
    width: 360px;
  }
}

.nav-upper {
  height: 95%;
  display: flex;
  width: 100%;
}

.nav-menu.active {
  background-color: #000000;
  translate: 0%;
  transition: 360ms;
  z-index: 50;
  /* flex-direction: column; */
}

.nav-menu-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;
  list-style: none;
  /* width: 100%; */
}

/* .nav-menu-item {
  width: 100%;
} */
.nav-menu-item a {
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 24px;
  padding: 8px 16px;
  border-radius: 5px;
}
.nav-menu-item a:hover {
  /* background-color: #000000; */
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-line: 5em;
}
.nav-menu-item a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px; /* Adjust the position of the line */
  background-color: black; /* Color of the line */
  transition: width 0.3s; /* Add a smooth transition effect for the line */
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
span {
  margin-left: 16px;
}

.menu-btn{
  display: flex;
  align-items: center;
}

.nav-header{
  background-color: #0000004f;
  display: flex;  
}
.nav-header a{
  color: #999999;
  text-decoration: none;
  transition: color 0.3s;
}
.nav-header a:hover {
  color: #fff; /* Change the color on hover */
}
@media (max-width: 767px) {
  .nav-header {
    width: 380px;
  }
}
.nav-footer {
  margin-bottom: 18px;
  /* margin-top: 2px; */
}

.owslogo {
  height: 60px;
  width: 65px;
}

.iglogo {
  height: 60px;
  width: 65px;
}
.nav-link1{
  font-family: "Kufam", sans-serif;
  margin-top: 17px;
  font-size: 20px;
  margin-right: 15px;
}

.nav-link2{
  font-family: "Kufam", sans-serif;
  margin-top: 15px;
  font-size: 20px;
}

.About {
  background-image: url('../assets/5.png'); /* Hover background */
  background-size: cover;
}
.Services {
  background-image: url('../assets/4.png'); /* Hover background */
  background-size: cover;
}
.Inquire {
  background-image: url('../assets/3.png'); 
  background-size: cover;
}
.Community {
  background-image: url('../assets/1.png'); /* Hover background */
  background-size: cover;
}
.Careers {
  background-image: url('../assets/2.png'); /* Hover background */
  background-size: cover;
}
/* .CaseStudies {
  background-image: url('../assets/8.jpg'); 
  background-size: cover;
} */

#navft{
  font-size: 15px;
  text-decoration: none;
  color: #999999;
}
#navft:hover{
  color: #fff;
}