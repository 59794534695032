.header-menu {
    position: absolute;
    top: 40px;
    right: 45px;
    z-index: 2;
  }

  .header-logo {
    position: absolute;
    top: 50px;
    left: 20px;
    rotate: -90deg;
    z-index: 2;
  }
  .header-logo:hover{
    transform: rotate(90deg);
    transition: transform 0.7s ease;
  }
  .header-logo:not(:hover) {
    transition: transform 0.7s ease;
  }
  .top-inline {
    color: #ffffff;
    font-size: 1.2rem;
    text-decoration: none;
    /* font-family: "Didact Gothic", sans-serif; */
    font-family: "Lora", serif;
    padding-bottom: 5px;
  }
  .inline-logo{
    /* color: #ffffff; */
    font-size: 2rem;
    text-decoration: none;
    font-weight: bold;
    /* padding-top: 18px; */
  }
  /* .top-inline:hover{
    transition: all 0.2s;
    border-bottom: 3px solid #ffffff;
  } */
  .Logoimg{
    width: 160px;
    height: 160px;
  }
  @media (max-width: 767px) {
    .header-logo {
      top: 130px;
      width: 40px;
      height: 40px;
    }
  }