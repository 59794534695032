* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   /* font-family: 'Lato', sans-serif; */
}


.home {
   /* background-color: black; */
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   font-size: 3rem;
   z-index: -1;
}

.background-video {
   height: 100vh;
   /* width: auto; */
   /* height: 650px; */
   width: 100%;
   object-fit: cover;
   position: absolute;
   overflow: hidden;
   top: 0;
   left: 0;
   z-index: -1;
}

.black-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: black;
   z-index: -2;
   /* Ensure it's behind the video */
}

@media (max-width: 767px) {
   .background-video {
      height: auto;
      /* Allow height to adjust based on video aspect ratio */
      width: 100%;
      /* Set width to fill the viewport width */
      position: fixed;
      /* Ensure the video stays fixed during scrolling */
      top: 50%;
      /* Move the video down by 50% of the viewport height */
      left: 0;
      /* Ensure it's aligned to the left edge */
      transform: translateY(-50%);
      /* Center the video vertically */
      z-index: -1;
      /* Keep the video behind other content */
   }
}

.content {
   position: relative;
   z-index: 1;
   color: #fff;
   /* Set text color to contrast with the video */
   /* Add other styling for your content */
   left: 20%;
   top: 30%;
}

.content h1 {
   font-size: 3rem;
}

/* <---------------Services page-----------> */
.services {
   margin-top: 12%;
   display: flex;
   width: auto;
   overflow-x: hidden;
}
.service{
   background-size: cover;
   background-repeat: no-repeat;
   height: 100vh;
   font-family: "Didact Gothic", sans-serif;
}

#description {
   width: 50%;
   height: 100vh;
   text-indent: 62px;
   font-family: "Lora", serif;
   margin: 60px 0 0 0;
   color: #ffffff;
   font-size: 22px;
   line-height: 1.5;
   text-align: justify;
   overflow: hidden;
}

#description a {
   color: #d8da74;
   text-decoration: none;
}

#description a:hover {
   color: #1dc0e9;
}

@media (max-width: 767px) {
   #description {
      margin: 0 0 0 0;
      font-size: 20px;
      overflow: hidden;
   }
}

.modal-90w {
   max-width: 80%;
   text-align: center;
}

.modal-title-center {
   display: flex;
   justify-content: center;
   width: 100%;
}

.font-link1 {
   font-family: "Nova Flat", system-ui;
}

.font-link2 {
   font-family: "Lora", serif;
}

/* <-------------End-------------> */

.videos-preview {
   width: 350px;
   height: 350px;
   /* position: absolute; */
   /* margin-left: 40px; */
   display: flex;
}

.Link01 {
   color: #bbb;
   text-decoration: none;
   font-size: 20px;
}

.Link01:hover {
   transition: all 0.2s;
   border-bottom: 3px solid #000000;
}

#btn1:hover {
   transition: all 0.2s;
   border-bottom: 3px solid #000000;
}

#btn2:hover {
   transition: all 0.2s;
   border-bottom: 3px solid #000000;
}

.blog {
   background-image: url('../assets/csr.png');
   background-size: cover;
   background-repeat: no-repeat;
   display: flex;
   /* flex-direction: column; */
   /* width: 100%; */
   height: 100vh;
   /* overflow-x: hidden; */
}
.webdev{
   background-image: url('../assets/34.png');
   background-size: cover;
   background-repeat: no-repeat;
   display: flex;
   height: 100vh;
}
.sofdev{
   background-image: url('../assets/35.png');
   background-size: cover;
   background-repeat: no-repeat;
   display: flex;
   height: 100vh;
}

/* <-------------Contact start------------> */

.contact {
   background-image: url('../assets/inquire.png');
   background-size: cover;
   background-repeat: no-repeat;
   height: 100vh;
   /* display: flex; */
   overflow: hidden;
   font-family: "Didact Gothic", sans-serif;
}

@media (max-width: 767px) {
   .contact {
      background-image: url('../assets/conMob.png');
   }
}

#reach{
   text-decoration: none;
   color: #fff;
   line-height: 2;
}

@media (max-width: 767px) {
   .contact {
      background-image: url('../assets/conMob.png');
   }
}



/* animation class and keyframes */
.overflow-hidden {
   overflow: hidden;
}

.flip-in {
   animation: flip-in 1.5s ease 400ms backwards;
   transform-origin: top right;
}

@keyframes flip-in {
   from {
      opacity: 0;
      transform: scaleX(0) scaleY(0) translateX(100%) translateY(-100%);
   }

   to {
      opacity: 1;
      transform: scaleX(1) scaleY(1) translateX(0) translateY(0);
   }
}

/* <-------------End---------------> */

.case_studies {
   /* background-image: url('../assets/case\ studies\ bg.png'); */
   height: 100vh;
   background-size: cover;
   background-repeat: no-repeat;
   object-fit: cover;
   display: flex;
}

.careers {
   background-image: url('../assets/Newcareer.png');
   height: 100vh;
   background-size: cover;
   background-repeat: no-repeat;
   object-fit: cover;
   display: flex;
   /* justify-content: center; */
   /* font-size: 3rem; */
}
.accordion-career {
   max-width: 170px; /* Adjust the max-width as needed */
   margin: 250px 200px;
   box-sizing: border-box;
 }


.sectors {
   background-color: #000;
   font-size: 3rem;
   height: 100vh;
   display: flex;
   flex-direction: column;
   gap: 8px;
   align-items: center;
   margin: 0;
   padding: 0;
   top: 0;
   bottom: 0;
}

.sectors h2 {
   color: #fff;
   display: flex;
   font-size: 4.5rem;
   /* align-items: center; */
}

.sec-menu {
   color: #ffffff;
   text-decoration: none;
   /* font-size: 1em; */
   letter-spacing: 0.1em;
   font-family: 'Times New Roman', Times, serif;
}

/* .sec-menu:hover {
            color:  #000000;
            -webkit-text-stroke: 3px rgb(10, 10, 10);
            -webkit-text-fill-color: white;
          } */
.sec-menu:hover {
   color: #333;
   -webkit-text-stroke: 1px #282828;
   text-shadow: 0px 4px 4px #ffffff;
}


/* VideoPreview */

.video-preview {
   position: absolute;
   top: 50%;
   left: 90%;
   transform: translate(-100%, -50%);
   display: flex;
   align-items: center;
}

video {
   width: 350px;
   display: flex;
   flex-direction: column;
   /* Set the width of the video preview */
   height: 350px;
   margin: auto;
   /* Maintain aspect ratio */
}


/* Slider in Service page */

.slider {
   width: 100%;
   height: 100vh;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
   margin: auto;
   overflow: hidden;
   
}

.slider-img {
   background-size: cover;
   background-repeat: no-repeat;
  height: 100vh;
  width: 225vh;
  object-fit: cover;
}

@media (max-width: 767px) {

   .slider-img {
      display: block;
      flex-direction: column;
      max-width: 100%;
      overflow: hidden;
   }
}

.slider-content {
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   height: 100%;
   position: relative;
}

.arrow-buttons {
   position: absolute;
   bottom: 10px;
   width: 100%;
   display: flex;
   justify-content: space-between;
   transform: translateY(-50%);
   padding: 10px 40px;
 }
 
 .prev, .next {
   background-color: rgba(153, 144, 144, 0.5);
   border: none;
   color: white;
   padding: 10px;
   cursor: pointer;
   font-size: 18px;
   margin: 0 20px;
 }
 
 .prev:hover, .next:hover {
   background-color: rgba(194, 182, 73, 0.8);
 }
 

#caption-overlay {
   width: 100%;
   position: absolute;
   /* top: 0;
   left: 0;
   right: 5%; */
   text-align: center;
   transform: translatex(-50%);
   bottom: 35px;
   left: 48%;
   /* background: rgba(0, 0, 0, 0.5); */
   display: flex;
   gap: 10px;
   padding: 10px;
}

#caption {
   /* margin: 75px 0 0 0; */
   position: absolute;
   letter-spacing: 4px;
   word-spacing: 12px;
   width: 50%;
   color: white; 
   transform: translatex(-50%);
   font-size: 300%;
   align-items: center;
   bottom: 35px;
   left: 48%;
   display: flex;
   gap: 10px;
}

@media (max-width: 767px) {
   #caption {
      width: 50%;
      font-size: 200%;
      margin: 150px 0 0 0;
   }
}

.discription {
   color: white;
   font-size: 10px;
   font-size: 150%;
}

/* <----------------Abt us page---------------> */
.about {
   background-image: url('../assets/aboutus.png');
   display: flex;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
   width: 100%;
   overflow-x: hidden;
   font-family: "Didact Gothic", sans-serif;
   font-size: 20px;
}

#abt-col1 {
   width: 53%;
   margin-right: 180px;
   font-family: "Lora", serif;
}

#abt-col2 {
   position: relative;  
   width: 270px;
   transform-style: preserve-3d;
   animation: rotateCube 15s infinite ;
   margin-top: 160px;
}

.abtH2 {
   text-align: left;
   color: #ffffff;
   display: flex;
   padding: 190px 0 0 170px;
}

.parR1 {
   text-align: justify;
   color: #ffffff;
   display: flex;
   padding: 0 0 0 170px;
}

.parR2 {
   text-align: justify;
   color: #ffffff;
   display: flex;
   padding: 0 0 0 170px;
}

.abt-vdpy {
   width: 100%;
   overflow: hidden;
   display: flex;
   margin: 0 auto;
   height: 90vh;
}

@media (max-width: 767px) {
   .about {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      overflow-x: hidden;
      /* overflow-y: hidden; */
   }
   .abtH2 {
      padding: 230px 0 0 120px;
   }
   .parR1 {
      padding: 0 0 0 120px;
   }
   .parR2 {
      padding: 0 0 0 120px;
   }
   #abt-col1 {
      width: auto;
      
   }

   #abt-col2 {
      width: auto;
   }

   .abt-vdpy {
      /* flex-direction: column-reverse; */
      padding: 0 0 0 90px;
   }
}

/* <--------------Abt Us end------------------------> */

/* Slidein-----------------Animation */
.animation-wrapper {
   position: relative;
   /* Ensure relative positioning for the absolute positioning of the image */
   overflow: hidden;
}

.slide-image {
   position: absolute;
   /* Position the image absolutely within its parent */
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* opacity: 1; */
   transform: translateX(-100%);
   transition: transform 1.5s ease;
}

.slide-in {
   transform: translateX(0);
   /* Slide out to the left */
}

/* <----------------------End---------------> */

form div {
   margin: 10px 20px 0 470px;
}

label {
   display: block;
   margin-bottom: 2px;
   color: #ffffff;
}

input,
textarea {
   width: 100%;
   padding: 8px;
   box-sizing: border-box;
}

button {
   padding: 10px 15px;
   background-color: #007bff;
   color: white;
   border: none;
   cursor: pointer;
   margin: 0 20px 0 470px;
}

button:hover {
   background-color: #0056b3;
}
.request{
   background-size: cover;
   background-repeat: no-repeat;
   height: 100vh;
   overflow: hidden;
}
.request h2{
   color: #fff;
}
#requst{
   padding: 120px 60px;
}
#succmsg{
   color: #2ddd53;
   background-color: #141414;
   width: 17%;
   position: absolute;
   right: 0;
   transform: translateY(-50%);
   font-size: 17px;
   font-weight: 700;
}
/* Pages.css */
.close-button {
   position: absolute;
   top: 10px;
   right: 10px;
   background: none;
   border: none;
   font-size: 1.5rem;
   cursor: pointer;
 }
.Cube{
   /* width: 50%; */
   position: absolute;
   width: 380px;
   height: 380px;
   backface-visibility: hidden;
   border: 1px solid #ccc;
   cursor: pointer;
}
.Cube:nth-child(1) { transform: rotateY(0deg) translateZ(190px); }
.Cube:nth-child(2) { transform: rotateY(180deg) translateZ(190px); }
.Cube:nth-child(3) { transform: rotateY(-90deg) translateZ(190px); }
.Cube:nth-child(4) { transform: rotateY(90deg) translateZ(190px); }
.Cube:nth-child(5) { transform: rotateX(90deg) translateZ(190px); }
.Cube:nth-child(6) { transform: rotateX(-90deg) translateZ(190px); }

@keyframes rotateCube {
  0% { transform: rotateX(0deg) rotateY(0deg); }
  25% { transform: rotateX(0deg) rotateY(90deg); }
  50% { transform: rotateX(0deg) rotateY(180deg); }
  75% { transform: rotateX(0deg) rotateY(270deg); }
  100% { transform: rotateX(0deg) rotateY(360deg); }
}

/* Modal styles */
.modal {
   display: block; /* Make sure this is 'block' when modal is open */
   position: fixed;
   z-index: 1;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: hidden;
   background-color: rgb(0,0,0); /* Fallback color */
   background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
 }
 
 .modal-content {
   background-color: #fefefe;
   margin: 6% auto;
   padding: 20px;
   border: none;
   width: 80%;
 }
 
 .close {
   color: #000000;
   float: right;
   font-size: 38px;
   font-weight: bold;
 }
 
 .close:hover,
 .close:focus {
   color: rgb(128, 100, 100);
   text-decoration: none;
   cursor: pointer;
 }
 .comm-links{
   position: absolute;
   left: 200px;
   top: 65%;
 }
 .comm-links .logu{
   height: 220px;
   width: 350px; 
   margin: 0;
 }
 .slide-text {
   color: none;
   position: absolute;
   width: 90%;
   height: 30%;
   top: 40%;
   left: 56%;
   transform: translate(-50%, -50%);
   padding: 30px;
   font-size: 158px;
   font-family: "Didact Gothic", sans-serif;
}

.image-container {
   /* margin-left: 100px ; */
   display: inline-block;
   cursor: pointer;
   overflow: hidden;
   transition: transform 0.3s ease-in-out;
 }
 
 .image-container.zoomed {
   transform: scale(2); /* Adjust the scale value as needed */
 }
 
 .image {
   width: 280px;
   height: 240px;
   /* margin: 380px 100px 0 0; */
   transition: transform 0.3s ease-in-out;
 }
 
 .image-container.zoomed .image {
   transform: scale(2); /* Adjust the scale value as needed */
 }
 

 .zoom-modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
 }
 
 .zoom-modal-content {
   position: relative;
   max-width: 90%;
   max-height: 90%;
 }
 
 .zoom-modal-image {
   width: 100%;
   height: auto;
   border-radius: 10px;
   transition: transform 0.3s ease-in-out;
 }

 /* src/components/Loading.css */
.loading-container {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: rgba(255, 255, 255, 0.8); /* Optional: add a semi-transparent background */
   z-index: 9999; /* Ensure it's on top of other elements */
 }
 
 .spinner {
   border: 6px solid #f3f3f3; /* Light grey, thinner border */
  border-top: 6px solid #fdb008; /* Blue, thinner border */
  border-radius: 50%;
  width: 150px; /* Slightly larger */
  height: 150px; /* Slightly larger */
   animation: spin 3s linear infinite;
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }
 .loading-text {
   margin-top: 20px; /* Add space between spinner and text */
   text-align: center;
 }
 
 .loading-text h4 {
   color: black;
   margin: 0; /* Remove default margin */
 }
 .Lodimg{
   width: 230px;
   height: 230px;
 }
 .hombtn{
   cursor: pointer;
   position: absolute;
   top: 80%;
 }