/* Custom styles for social media icons */
.social-icons {
    display: flex;
    gap: 50px;
  }
  
  .social-icons a {
    color: #333; /* Change the color as needed */
    font-size: 24px; /* Adjust the font size as needed */
    transition: color 0.3s; /* Add a smooth transition effect for color change */
  }
  .social-icons a:hover {
    color: #fff; /* Change the color on hover */
  }